.controls_container {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: -30px;
}

.balance_indicator_container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.tumble_history_container {
  position: absolute;
  top: 70px;
  left: 10px;
}

.tumble_win_container {
  position: absolute;
  top: 30px;
  width: 100%;
  text-align: -webkit-center;
}
