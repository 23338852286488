.toggleButton {
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}
.toggleButton .toggleThumb {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    transition: left 0.3s;
}
.toggleButton.active {
    background-color: #4caf50;
}
.toggleButton.active .toggleThumb {
    left: 32px;
}