.payTableRow {
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.payCellInfo {
  align-items: center;
}

.headerImageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  position: absolute;
  margin-left: -24px;
  margin-top: -67px;
}

.leftMarkImageContainer {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -20px;
  margin-left: -51px;
}

.rightMarkImageContainer {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -20px;
  margin-left: 3px;
  rotate: 180deg;
  width: 100%;
}

.historyHeader {
  font-size: 10px;
}
